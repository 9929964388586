import * as React from "react";
const SvgGenericCreditCard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={38}
    fill="none"
    {...props}
  >
    <rect width={63} height={37} x={0.5} y={0.5} stroke="#E6E6E6" rx={0.5} />
    <path fill="#E6E6E6" d="M0 9h64v10H0z" />
  </svg>
);
export default SvgGenericCreditCard;
