import { registerEffectHandler } from 'reffects';
import { isBackofficeUserSelector } from '../selectors/user';
import { isPublisherForTestingSelector } from '../selectors/publisher';

export default function registerAnalyticsEffects(
  analytics,
  store,
  disableTracking,
  log = console.log
) {
  registerEffectHandler(
    'analytics.track',
    triggerWhenTrackingNotDisabledForUser(
      ({ name, section, type, payload }) => {
        const { locale, user, publisher, device } = store.getState();

        analytics.track({
          name: `web_${name}`,
          type,
          section,
          userId: user?.id ? user.id : '00000000-0000-7000-0000-000000000000', // Anonymous user,
          publisherId: publisher?.id,
          subscriptionId: publisher?.subscriptionId,
          countryCode: publisher?.countryCode,
          locale,
          source: 'web',
          deviceType: device?.type,
          payload,
          ...payload,
        });
      }
    )
  );

  registerEffectHandler(
    'analytics.page',
    triggerWhenTrackingNotDisabledForUser(({ name, payload }) => {
      const { locale, user, publisher, device } = store.getState();

      analytics.page({
        name: `web_${name}`,
        userId: user?.id,
        publisherId: publisher?.id,
        subscriptionId: publisher?.subscriptionId,
        countryCode: publisher?.countryCode,
        locale,
        source: 'web',
        deviceType: device?.type,
        payload,
        ...payload,
      });
    })
  );

  registerEffectHandler(
    'analytics.identify',
    triggerWhenTrackingNotDisabledForUser(({ userId, traits }) => {
      analytics.identify(userId, traits);
    })
  );

  function triggerWhenTrackingNotDisabledForUser(f) {
    return (params) => {
      if (disableTracking === 'true') {
        log(
          'Not tracked because tracking is disabled in current environment.',
          params
        );
        return;
      }

      const state = store.getState();
      const isBackofficeUser = isBackofficeUserSelector(state);
      const isPublisherForTesting = isPublisherForTestingSelector(state);

      if (isPublisherForTesting) {
        log('Not tracked because the publisher is for testing.', params);
        return;
      }

      if (isBackofficeUser) {
        log('Not tracked because the user is from backoffice.', params);
        return;
      }
      f(params);
    };
  }
}

export const analytics = {
  trackClick(name, section, payload = {}) {
    return {
      'analytics.track': {
        name,
        section,
        type: 'click',
        payload,
      },
    };
  },
  trackImpression(elementName, section, payload) {
    return {
      'analytics.track': {
        name: elementName,
        section,
        type: 'impression',
        payload,
      },
    };
  },
  trackPageView(name, payload) {
    return {
      'analytics.page': {
        name,
        payload,
      },
    };
  },
  trackIdentifyUser(userId, traits) {
    return { 'analytics.identify': { userId, traits } };
  },
};
