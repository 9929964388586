import { PublishedButBoostingLimitReachedModal } from './variants/PublishedButBoostingLimitReachedModal';
import { PublishedButBoostingForbiddenModal } from './variants/PublishedButBoostingForbiddenModal';
import { BoostingForbiddenModal } from './variants/BoostingForbiddenModal';
import { BoostingLimitReachedModal } from './variants/BoostingLimitReachedModal';
import { SuperboostingLimitReachedModal } from './variants/SuperboostingLimitReachedModal';
import { SuperboostingForbiddenModal } from './variants/SuperboostingForbiddenModal';
import { SuperboostingLimitReachedForBoostingModal } from './variants/SuperboostingLimitReachedForBoostingModal';
import { NotPublishedModal } from './variants/NotPublishedModal';
import { SitesPublishedOnModal } from './variants/SitesPublishedOnModal/SitesPublishedOnModal';
import AdFeedbackModal from './variants/AdFeedbackModal';
import { NotPublishedErrorModal } from './variants/NotPublishedErrorModal';
import { BOOSTING_LIMIT_REACHED_MODAL } from './events';

export default function AdModals() {
  return (
    <>
      <NotPublishedModal />
      <NotPublishedErrorModal />
      <SitesPublishedOnModal />
      <BoostingForbiddenModal />
      <BoostingLimitReachedModal dialog={BOOSTING_LIMIT_REACHED_MODAL} />
      <PublishedButBoostingForbiddenModal />
      <PublishedButBoostingLimitReachedModal />
      <SuperboostingLimitReachedModal />
      <SuperboostingLimitReachedForBoostingModal />
      <SuperboostingForbiddenModal />
      <AdFeedbackModal />
    </>
  );
}
