import Dialog from 'design-system/components/Dialog/Dialog';
import { SecondaryButton } from 'design-system/components/Button/presets';
import { useSelector } from 'reffects-store';
import { supportEmailSelector } from '../../../../selectors/config';
import { useDialog } from '../../../../hooks/useDialog';
import { SUPERBOOSTING_LIMIT_REACHED_MODAL } from '../events';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';

import { UpsellSuperBoostingLimitReachedModal } from './UpsellSuperboostingLimitReachedModal';

export function SuperboostingLimitReachedModal() {
  const { open, closeDialog } = useDialog(SUPERBOOSTING_LIMIT_REACHED_MODAL);
  const supportEmail = useSelector(supportEmailSelector);

  const UpsellSuperBoosting = UpsellSuperBoostingLimitReachedModal(
    'upsell-superboost-modal-properties-page'
  );
  if (UpsellSuperBoosting) {
    return UpsellSuperBoosting;
  }

  return (
    <Dialog open={open}>
      {getDefaultDialogBody(supportEmail)}
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="freelistings_button_continue" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

function getDefaultDialogBody(supportEmail) {
  return (
    <>
      <Dialog.Header>
        <FormattedMessageWithValidation id="superboosting_limit_reached_modal_title" />
      </Dialog.Header>
      <Dialog.Emphasis>
        <FormattedMessageWithValidation id="superboosting_limit_reached_modal_message" />
      </Dialog.Emphasis>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation
            id="superboosting_limit_reached_modal_text"
            values={{
              supportEmail: (
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
              ),
            }}
          />
        </Dialog.Text>
      </Dialog.Content>
    </>
  );
}
