import { effects as reffects, effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { toast } from '../../../effects/toast';
import { analytics } from '../../../effects/analytics';
import { OPEN_DIALOG } from '../../../events/dialogs';
import isTHPropertyTheOnlyBannedBrand from './isTHPropertyTheOnlyBannedBrand';
import { environment } from '../../../coeffects/environment';
import { redirectTo } from '../../../effects/routing';
import { tracking } from '../../../effects/tracking';

export const SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION =
  'SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION';
export const SHOW_AD_PUBLISHING_ERROR_NOTIFICATION =
  'SHOW_AD_PUBLISHING_ERROR_NOTIFICATION';
export const SHOW_AD_BULK_STATUS_NOTIFICATION =
  'SHOW_AD_BULK_STATUS_NOTIFICATION';
export const OPEN_BOOSTING_FORBIDDEN_MODAL = 'OPEN_BOOSTING_FORBIDDEN_MODAL';
export const OPEN_BOOSTING_LIMIT_REACHED_MODAL =
  'OPEN_BOOSTING_LIMIT_REACHED_MODAL';
export const OPEN_BOOSTING_LIMIT_REACHED_MODAL_MX =
  'OPEN_BOOSTING_LIMIT_REACHED_MODAL_MX';
export const OPEN_PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL =
  'OPEN_PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL';
export const OPEN_PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL =
  'OPEN_PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL';
export const OPEN_SITES_PUBLISHED_ON_MODAL = 'OPEN_SITES_PUBLISHED_ON_MODAL';

export const BOOSTING_FORBIDDEN_MODAL = 'boostingForbiddenModal';
export const BOOSTING_LIMIT_REACHED_MODAL = 'boostingLimitReachedModal';
export const UPSELL_BOOSTING_MODAL = 'upsellBoostingModal';
export const PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL =
  'publishedButBoostingForbiddenModal';
export const NOT_PUBLISHED_MODAL = 'notPublishedModal';
export const NOT_PUBLISHED_ERROR_MODAL = 'notPublishedErrorModal';
export const PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL =
  'publishedButBoostingLimitReachedModal';
export const SITES_PUBLISHED_ON_MODAL = 'sitesPublishedOnModal';
export const SUPERBOOSTING_FORBIDDEN_MODAL = 'superboostingForbiddenModal';
export const SUPERBOOSTING_LIMIT_REACHED_MODAL =
  'superboostingLimitReachedModal';
export const OPEN_SUPERBOOSTING_LIMIT_REACHED_MODAL =
  'OPEN_SUPERBOOSTING_LIMIT_REACHED_MODAL';
export const SUPERBOOSTING_LIMIT_REACHED_FOR_BOOSTING_MODAL =
  'superboostingLimitReachedForBoostingModal';

export const UPSELL_SUPERBOOST_REQUEST = 'UPSELL_SUPERBOOST_REQUEST';
export const UPSELL_SUPERBOOST_SUCCESS = 'UPSELL_SUPERBOOST_SUCCESS';
export const CREATE_UPSELL_SUPERBOOST_REQUEST =
  'CREATE_UPSELL_SUPERBOOST_REQUEST';
export const CREATE_UPSELL_SUPERBOOST_CTA_CLICKED =
  'CREATE_UPSELL_SUPERBOOST_CTA_CLICKED';

export const CREATE_UPSELL_SUPERBOOST_SUCCESS =
  'CREATE_UPSELL_SUPERBOOST_SUCCESS';
export const CREATE_UPSELL_SUPERBOOST_REQUEST_MODAL_VIEWED =
  'CREATE_UPSELL_SUPERBOOST_REQUEST_MODAL_VIEWED';
export const OPEN_BOOSTING_LIMIT_REACHED_MODAL_BY_COUNTRY =
  'OPEN_BOOSTING_LIMIT_REACHED_MODAL_BY_COUNTRY';

const BULK_COMPLETE_TOAST_TAGS = {
  publish: 'propspage_bulk_toast_confirmation_publish_activated',
  unpublish: 'propspage_bulk_toast_confirmation_unpublish',
  boost: 'propspage_bulk_toast_confirmation_boosting',
  unboost: 'propspage_bulk_toast_confirmation_deactivate',
  delete: 'propspage_bulk_toast_confirmation_delete',
};

function showSuperboostingFeedback({
  publisherWithNoSuperboosting,
  section,
  adWasSuperboosted,
  publisherBoostingLimitReached,
  publisherSuperboostingLimitReached,
}) {
  if (publisherWithNoSuperboosting) {
    return openSuperboostingForbiddenModal(section);
  }
  if (
    !publisherWithNoSuperboosting &&
    !adWasSuperboosted &&
    publisherSuperboostingLimitReached
  ) {
    return openSuperboostingLimitReachedModal(section);
  }
  if (
    !publisherWithNoSuperboosting &&
    !adWasSuperboosted &&
    publisherBoostingLimitReached
  ) {
    return openSuperboostingLimitReachedForBoostingModal(section);
  }
  if (!publisherSuperboostingLimitReached && !publisherWithNoSuperboosting) {
    return openSuperboostedToast();
  }
  return {};
}

function showBoostingFeedback(
  apiUrl,
  publisherId,
  {
    publisherSubscribedToFreePlan,
    section,
    adWasBoosted,
    publisherBoostingLimitReached,
    countryCode,
  }
) {
  if (publisherSubscribedToFreePlan && countryCode !== 'MX') {
    return openBoostingForbiddenModal(apiUrl, publisherId, section);
  }
  if (
    !publisherSubscribedToFreePlan &&
    !adWasBoosted &&
    publisherBoostingLimitReached &&
    countryCode !== 'MX'
  ) {
    return openBoostingLimitReachedModal(apiUrl, section);
  }
  if (!publisherBoostingLimitReached && !publisherSubscribedToFreePlan) {
    return openBoostedToast();
  }
  return {};
}

function showPublishAndBoostFeedback(
  apiUrl,
  publisherId,
  {
    publisherSubscribedToFreePlan,
    section,
    publisherBoostingLimitReached,
    numberOfPublishedAdsExceedBoostingLimit,
    adWasPublished,
    adWasBoosted,
    countryCode,
    bannedBrandsForFreeListings,
  }
) {
  if (!adWasPublished) {
    return openNotPublishedModal(section);
  }
  if (publisherSubscribedToFreePlan && countryCode !== 'MX') {
    return openPublishedButBoostingForbiddenModal(apiUrl, publisherId, section);
  }
  if (!publisherBoostingLimitReached && !publisherSubscribedToFreePlan) {
    if (countryCode === 'MX' && numberOfPublishedAdsExceedBoostingLimit) {
      return openBoostingLimitReachedModalMx(apiUrl, section);
    }
    return effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SITES_PUBLISHED_ON_MODAL,
        parameters: { isBoosted: adWasBoosted },
      },
    });
  }
  if (
    !publisherSubscribedToFreePlan &&
    !adWasBoosted &&
    publisherBoostingLimitReached
  ) {
    if (
      bannedBrandsForFreeListings?.length > 0 &&
      !isTHPropertyTheOnlyBannedBrand(bannedBrandsForFreeListings)
    ) {
      return openSitesPublishedOnModal(apiUrl, adWasBoosted, section);
    }
    if (countryCode !== 'MX') {
      return openPublishedButBoostingLimitReachedModal(apiUrl, section);
    }
  }

  return {};
}

registerEventHandler(
  SHOW_AD_PUBLISHING_AND_BOOSTING_STATUS_NOTIFICATION,
  (
    {
      environment: { apiUrl },
      state: { publisherId, countryCode, bannedBrandsForFreeListings },
    },
    payload
  ) => {
    const { userAction } = payload;

    if (userAction === 'superboost') {
      return showSuperboostingFeedback(payload);
    }
    if (userAction === 'boost') {
      return showBoostingFeedback(apiUrl, publisherId, {
        ...payload,
        countryCode,
      });
    }
    if (userAction === 'publish_and_boost') {
      return showPublishAndBoostFeedback(apiUrl, publisherId, {
        ...payload,
        countryCode,
        bannedBrandsForFreeListings,
      });
    }
    if (userAction === 'save_as_draft') {
      return openSavedAsDraftToast();
    }
    return {};
  },
  [
    environment(),
    state.get({
      publisherId: 'publisher.id',
      countryCode: 'publisher.countryCode',
      bannedBrandsForFreeListings: 'publisher.bannedBrandsForFreeListings',
    }),
  ]
);
registerEventHandler(
  SHOW_AD_PUBLISHING_ERROR_NOTIFICATION,
  () => ({
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: NOT_PUBLISHED_ERROR_MODAL,
      },
    }),
    ...analytics.trackPageView('not-published-error-modal', {
      section: 'publishing-error-draft',
    }),
  }),
  []
);

registerEventHandler(SHOW_AD_BULK_STATUS_NOTIFICATION, (_, payload) => {
  const { userAction, countProcessedAds } = payload;
  if (
    userAction === 'publish' &&
    countProcessedAds === 0 &&
    payload.publisherSubscribedToFreePlan
  ) {
    return {
      ...toast.hide(),
      ...reffects.dispatch({
        id: OPEN_DIALOG,
        payload: {
          id: NOT_PUBLISHED_MODAL,
        },
      }),
    };
  }
  return toast.show({
    text: BULK_COMPLETE_TOAST_TAGS[userAction],
    values: { n: countProcessedAds },
    milliseconds: 2000,
  });
});

function openSuperboostingForbiddenModal(section) {
  return {
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SUPERBOOSTING_FORBIDDEN_MODAL,
      },
    }),
    ...analytics.trackPageView('superboosting-forbidden-modal', { section }),
  };
}

registerEventHandler(
  OPEN_BOOSTING_FORBIDDEN_MODAL,
  (_, [{ data }, { section }]) => ({
    ...state.set({ offers: Array.isArray(data) ? data : [] }),
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: BOOSTING_FORBIDDEN_MODAL,
      },
    }),
    ...analytics.trackPageView('boosting-forbidden-modal', { section }),
  })
);

registerEventHandler(
  OPEN_BOOSTING_LIMIT_REACHED_MODAL,
  (_, [{ data }, { section, newSection }]) => ({
    ...state.set({ upsellOffers: Array.isArray(data) ? data : [] }),
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: newSection ? UPSELL_BOOSTING_MODAL : BOOSTING_LIMIT_REACHED_MODAL,
        parameters: { section: newSection ?? 'boosting-limit-reached-modal' },
      },
    }),
    ...analytics.trackPageView('boosting-limit-reached-modal', {
      section: newSection ?? section,
    }),
  })
);

registerEventHandler(
  OPEN_BOOSTING_LIMIT_REACHED_MODAL_MX,
  (_, [{ data }, { section, newSection }]) =>
    !Array.isArray(data) || data.length === 0
      ? {}
      : {
          ...state.set({ upsellOffers: data }),
          ...reffects.dispatch({
            id: OPEN_DIALOG,
            payload: {
              id: newSection
                ? UPSELL_BOOSTING_MODAL
                : BOOSTING_LIMIT_REACHED_MODAL,
              parameters: {
                section: newSection ?? 'boosting-limit-reached-modal',
              },
            },
          }),
          ...analytics.trackPageView('boosting-limit-reached-modal', {
            section: newSection ?? section,
          }),
        }
);

registerEventHandler(
  OPEN_PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL,
  (_, [{ data }, { section }]) => ({
    ...state.set({ offers: Array.isArray(data) ? data : [] }),
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL,
      },
    }),
    ...analytics.trackPageView('published-but-boosting-forbidden-modal', {
      section,
    }),
  })
);

registerEventHandler(
  OPEN_PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL,
  (_, [{ data }, { section }]) => ({
    ...state.set({ upsellOffers: Array.isArray(data) ? data : [] }),
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL,
        parameters: { section: 'published-but-boosting-limit-reached-modal' },
      },
    }),
    ...analytics.trackPageView('published-but-boosting-limit-reached-modal', {
      section,
    }),
  })
);

registerEventHandler(
  OPEN_SITES_PUBLISHED_ON_MODAL,
  (_, [{ data }, { adWasBoosted, section }]) => {
    if (Array.isArray(data) && data.length > 0) {
      return reffects.dispatch({
        id: OPEN_BOOSTING_LIMIT_REACHED_MODAL,
        payload: [{ data }, { section }],
      });
    }
    return reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SITES_PUBLISHED_ON_MODAL,
        parameters: { isBoosted: adWasBoosted },
      },
    });
  }
);

registerEventHandler(
  UPSELL_SUPERBOOST_REQUEST,
  ({ environment: { apiUrl } }) => ({
    ...http.get({
      url: `${apiUrl}/self-service/plans/upsell/superboost-price`,
      successEvent: { id: UPSELL_SUPERBOOST_SUCCESS },
    }),
  }),
  [environment()]
);

registerEventHandler(
  CREATE_UPSELL_SUPERBOOST_REQUEST,
  ({ environment: { apiUrl } }, { section }) => ({
    ...http.post({
      url: `${apiUrl}/self-service/plans/upsell/superboost?origin=${section}`,
      successEvent: { id: CREATE_UPSELL_SUPERBOOST_SUCCESS },
    }),
  }),
  [environment()]
);

registerEventHandler(
  CREATE_UPSELL_SUPERBOOST_CTA_CLICKED,
  (_, { section }) => ({
    ...tracking.track('UpsellSuperboostClicked', section),
  })
);

registerEventHandler(
  CREATE_UPSELL_SUPERBOOST_REQUEST_MODAL_VIEWED,
  (_, { section }) => ({
    ...tracking.track('UpsellSuperboostViewed', section),
  })
);

registerEventHandler(UPSELL_SUPERBOOST_SUCCESS, (_, [{ data }]) =>
  state.set({ superboostUpsell: data.superboostUpsell })
);

registerEventHandler(CREATE_UPSELL_SUPERBOOST_SUCCESS, (_, [{ data }]) =>
  redirectTo(data.link)
);

function openBoostingForbiddenModal(apiUrl, publisherId, section) {
  return http.get({
    url: `${apiUrl}/self-service/plans?publisherId=${publisherId}`,
    successEvent: { id: OPEN_BOOSTING_FORBIDDEN_MODAL, payload: { section } },
    errorEvent: { id: OPEN_BOOSTING_FORBIDDEN_MODAL, payload: { section } },
  });
}

function openNotPublishedModal(section) {
  return {
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: NOT_PUBLISHED_MODAL,
      },
    }),
    ...analytics.trackPageView('not-published-modal', {
      section,
    }),
  };
}

function openPublishedButBoostingForbiddenModal(apiUrl, publisherId, section) {
  return http.get({
    url: `${apiUrl}/self-service/plans?publisherId=${publisherId}`,
    successEvent: {
      id: OPEN_PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL,
      payload: { section },
    },
    errorEvent: {
      id: OPEN_PUBLISHED_BUT_BOOSTING_FORBIDDEN_MODAL,
      payload: { section },
    },
  });
}

function openSuperboostingLimitReachedModal(section) {
  return {
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SUPERBOOSTING_LIMIT_REACHED_MODAL,
      },
    }),
    ...analytics.trackPageView('superboosting-limit-reached-modal', {
      section,
    }),
  };
}

function openSuperboostingLimitReachedForBoostingModal(section) {
  return {
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SUPERBOOSTING_LIMIT_REACHED_FOR_BOOSTING_MODAL,
      },
    }),
    ...analytics.trackPageView(
      'superboosting-limit-reached-for-boosting-modal',
      {
        section,
      }
    ),
  };
}

function openBoostingLimitReachedModal(apiUrl, section) {
  return http.get({
    url: `${apiUrl}/self-service/plans/upsell`,
    successEvent: {
      id: OPEN_BOOSTING_LIMIT_REACHED_MODAL,
      payload: { section },
    },
    errorEvent: { id: OPEN_BOOSTING_LIMIT_REACHED_MODAL, payload: { section } },
  });
}

function openBoostingLimitReachedModalMx(apiUrl, section) {
  return http.get({
    url: `${apiUrl}/self-service/plans/upsell`,
    successEvent: {
      id: OPEN_BOOSTING_LIMIT_REACHED_MODAL_MX,
      payload: { section },
    },
    errorEvent: {
      id: OPEN_BOOSTING_LIMIT_REACHED_MODAL_MX,
      payload: { section },
    },
  });
}

function openPublishedButBoostingLimitReachedModal(apiUrl, section) {
  return http.get({
    url: `${apiUrl}/self-service/plans/upsell`,
    successEvent: {
      id: OPEN_PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL,
      payload: { section },
    },
    errorEvent: {
      id: OPEN_PUBLISHED_BUT_BOOSTING_LIMIT_REACHED_MODAL,
      payload: { section },
    },
  });
}

function openSitesPublishedOnModal(apiUrl, adWasBoosted, section) {
  return http.get({
    url: `${apiUrl}/self-service/plans/upsell`,
    successEvent: {
      id: OPEN_SITES_PUBLISHED_ON_MODAL,
      payload: { adWasBoosted, section },
    },
    errorEvent: {
      id: OPEN_SITES_PUBLISHED_ON_MODAL,
      payload: { adWasBoosted, section },
    },
  });
}

function openSuperboostedToast() {
  return toast.show({
    text: 'propspage_toast_confirmation_superboosting',
  });
}

function openBoostedToast() {
  return toast.show({
    text: 'propspage_toast_confirmation_boosting',
  });
}

function openSavedAsDraftToast() {
  return toast.show({
    text: 'propspage_saved_draft',
  });
}

registerEventHandler(
  OPEN_SUPERBOOSTING_LIMIT_REACHED_MODAL,
  (_, { section }) => ({
    ...state.set({ superboostLimitReachedModalShowed: true }),
    ...reffects.dispatch({
      id: OPEN_DIALOG,
      payload: {
        id: SUPERBOOSTING_LIMIT_REACHED_MODAL,
        parameters: { section },
      },
    }),
  })
);

registerEventHandler(
  OPEN_BOOSTING_LIMIT_REACHED_MODAL_BY_COUNTRY,
  ({ environment: { apiUrl }, state: { countryCode } }, { section }) => {
    let idEvent = OPEN_BOOSTING_LIMIT_REACHED_MODAL;
    if (countryCode === 'MX') {
      idEvent = OPEN_BOOSTING_LIMIT_REACHED_MODAL_MX;
    }
    return {
      ...http.get({
        url: `${apiUrl}/self-service/plans/upsell`,
        successEvent: {
          id: idEvent,
          payload: { newSection: section },
        },
        errorEvent: {
          id: idEvent,
          payload: { newSection: section },
        },
      }),
      ...state.set({ boostingLimitReachedModalShowed: true }),
    };
  },
  [
    environment(),
    state.get({
      countryCode: 'publisher.countryCode',
    }),
  ]
);
