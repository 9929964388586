import {
  isAgencySelector,
  isPremiumSubscriptionSelector,
  isPublisherFromLATAMSelector,
  publisherCountrySelector,
} from './publisher';
import { getFeatureFlagForPublisherCountrySelector } from '../utils/featuresFlags/selectors';
import { COUNTRY_CODE } from '../constants/country';

export function isPremiumEnabledSelector(state) {
  const TEASE_PREMIUM_4271 = getFeatureFlagForPublisherCountrySelector(
    state,
    'TEASE_PREMIUM_4271'
  );
  return TEASE_PREMIUM_4271 && isPublisherFromLATAMSelector(state);
}

export function shouldTeasePremiumSubscriptionSelector(state) {
  return (
    isPremiumEnabledSelector(state) &&
    !isPremiumSubscriptionSelector(state) &&
    isAgencySelector(state)
  );
}

export function shouldTeaseCallDurationSelector(state) {
  return ![COUNTRY_CODE.ECUADOR, COUNTRY_CODE.MEXICO].includes(
    publisherCountrySelector(state)
  );
}
