import Icon from 'design-system/components/Icon';
import styled from 'styled-components';
import Tag, {
  TAG_ICON_POSITION,
  TAG_VARIANT,
} from 'design-system/components/Tag';
import { useSelector } from 'reffects-store';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { TOOLTIP_MODAL_PLACEMENT } from 'design-system/components/TooltipModal/TooltipModal';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { shouldTeasePremiumSubscriptionSelector } from '../../../../selectors/premium';
import UnlockPremium from '../../../../partials/UnlockPremium';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const GreenTag = styled(Tag)`
  ${Tag.mods.iconPosition(TAG_ICON_POSITION.START)}
  ${Tag.mods.variant(TAG_VARIANT.PRIMARY)}
`;

export default function IncomingCallActivityCall({ duration }) {
  const shouldTeasePremium = useSelector(
    shouldTeasePremiumSubscriptionSelector
  );

  if (shouldTeasePremium) {
    return (
      <Wrapper>
        <GreenTag icon={<Icon glyph="callIncoming" />}>
          <FormattedMessageWithValidation id="enquiries_call_incoming" />
        </GreenTag>
        <UnlockPremium
          tooltipTag="unlock_premium_tooltip_call_duration"
          labelTag="unlock_premium_call_duration"
          tooltipPlacement={TOOLTIP_MODAL_PLACEMENT.DOWN}
        />
      </Wrapper>
    );
  }

  return (
    <GreenTag icon={<Icon glyph="callIncoming" />}>
      {duration ? (
        <FormattedMessageWithValidation
          id="enquiries_call_incoming_with_duration"
          values={{ duration }}
        />
      ) : (
        <FormattedMessageWithValidation id="enquiries_call_incoming" />
      )}
    </GreenTag>
  );
}
